<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="row" style="background-color: #ecf0f3">
        <div class="col-md-2 client_profile_thumb pt-7 pl-20">
          <v-avatar v-if="url != null" size="128">
            <img :src="url" />
          </v-avatar>
          <v-avatar v-else size="128">
            <img src="../../../public/media/users/2.png" />
          </v-avatar>
          <v-file-input
            v-if="this.otherStaffId"
            style="margin-top: -30px !important; margin-left: 90px"
            @change="Preview_image"
            v-model="image"
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            hide-input
          ></v-file-input>
          <v-avatar v-if="url != null" style="margin-top: -46px !important">
            <button @click="Delete()" class="mr-1">
              <i style="color: black" class="fas fa-trash-alt del_btn"></i>
            </button>
          </v-avatar>
          <!-- <figure class="upload-img">

            <img
              id="imgClientProfile"
              src="../../public/media/users/images.png"
            />
          </figure> -->
        </div>
        <div class="col-md-10 pl-15 pt-15">
          <div class="row pl-3">
            <h3>
              <span v-if="form.lastName" id="lblMFname"
                >{{ form.lastName }},
              </span>
              <span v-if="form.firstName" id="lblMLastName"
                >{{ form.firstName }}
              </span>
            </h3>
            <span
              v-if="form.empID"
              id="lblMAge"
              class="pl-2 pt-1"
              style="position: relative"
            >
              ID : {{ form.empID }}</span
            >
          </div>
          <div>
            <span v-if="form.employeeType">
              <a id="linkbtnPayer">{{ form.employeeType }}</a>
            </span>
            <br />
            <span
              v-if="
                form.employeeContactNumbers &&
                form.employeeContactNumbers.length
              "
            >
              <a id="linkbtnPayer">{{
                form.employeeContactNumbers[0].phoneNumber
              }}</a>
            </span>
            <br />
            <span>
              <a
                id="linkbtnAddress"
                class="font-blue link"
                target="_blank"
                :href="
                  'https://maps.google.com/?q=' +
                  form.latitude +
                  ',' +
                  form.latitude
                "
                >{{ form.address }} {{ form.otherAddress }}
                <!-- , {{ form.city }} , {{ form.state }},
                {{ form.zip } -->
              </a>
            </span>
            <span>
              <div v-if="this.form.hireDate" class="font-blue">
                Hire Date: {{ foramtDateHeading(form.hireDate) }}
              </div>
            </span>
            <span>
              <div class="font-blue mt-2">
                <a
                  id="status"
                  v-bind:style="{
                    color: statusColor,
                    'background-color': statusBackGroundColor,
                    padding: '5px',
                  }"
                  >{{ form.status }}</a
                >
              </div>
            </span>
          </div>
        </div>
      </div>
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div
          class="wizard-nav border-bottom mb-1 mb-lg-5 sticky-top"
          style="background-color: white; z-index: 50 !important"
        >
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">INFORMATION</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">HR</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">TASK/COMMUNICATIONS</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">DOCUMENTS</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">HISTORY</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center">
              <div class="col-xl-12 col-sm-12 col-md-12 col-xxl-7">
                <form class="form mt-0" id="kt_form">
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <Information @updateData="updateData"></Information>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <hrcomponent v-if="this.otherStaffId"></hrcomponent>
                    <div v-else>
                      <center style="color: red">
                        Please Fill Employee Info first
                      </center>
                    </div>
                  </div>

                  <div class="pb-5" data-wizard-type="step-content">
                    <div>
                      <task v-if="this.otherStaffId"></task>
                      <div v-else>
                        <center style="color: red">
                          Please Fill Employee Info first
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div>
                      <documentGiver v-if="this.otherStaffId"></documentGiver>
                      <div v-else>
                        <center style="color: red">
                          Please Fill Employee Info first
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content"></div>
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase ml-5 px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="Close"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4 mr-5 mb-3"
                      >
                        Close
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 mr-5 mb-3 py-4"
                        data-wizard-type="action-next"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="xl" id="siblings" centered no-close-on-backdrop>
      <template slot="modal-title">Add Siblings</template>
      <div class="row p-2">
        <div
          class="form-group col-md-4 mb-form"
          :class="{ invalid: $v.siblings.firstName.$error }"
        >
          <v-text-field
            v-model.trim="$v.siblings.firstName.$model"
            label="FirstName"
            maxlength="50"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.siblings.firstName.$error">
            <span v-if="$v.siblings.firstName.$error"
              >First Name is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-4 mb-form"
          :class="{ invalid: $v.siblings.lastName.$error }"
        >
          <v-text-field
            v-model.trim="$v.siblings.lastName.$model"
            label="Last Name"
            maxlength="50"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.siblings.lastName.$error">
            <span v-if="$v.siblings.lastName.$error"
              >Last Name is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-4 mb-form"
          :class="{ invalid: $v.siblings.email.$error }"
        >
          <v-text-field
            v-model.trim="$v.siblings.email.$model"
            label="Email"
            maxlength="20"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.siblings.email.$error">
            <span v-if="$v.siblings.email.$error"
              >please enter valid email</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-4 mb-form"
          :class="{ invalid: $v.siblings.relation.$error }"
        >
          <v-text-field
            v-model.trim="$v.siblings.relation.$model"
            label="Relation"
            maxlength="20"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.siblings.relation.$error">
            <span v-if="$v.siblings.relation.$error">Relation is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-4 mb-form"
          :class="{ invalid: $v.siblings.uci.$error }"
        >
          <v-text-field
            v-model.trim="$v.siblings.uci.$model"
            label="UCI"
            type="number"
            maxlength="7"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.siblings.uci.$error">
            <span v-if="$v.siblings.uci.$error">Uci is required</span>
          </div>
        </div>
        <v-col class="d-flex" cols="12" sm="4">
          <v-checkbox
            v-model="siblings.portalCredentialsSent"
            label=" Portal Credentials Sent?"
          ></v-checkbox>
        </v-col>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="lg" variant="success" @click="Addsibling()">
          Add
          <span v-if="isSiblingSaving">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import task from "../../components/task";
import hrcomponent from "../../components/careGiver/HR";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { fetchApi } from "../../core/services/request";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import AddGoogleMap from "../../components/AddGoogleMap";
import Information from "../../components/otherStaff/info.vue";
import documentGiver from "../../components/careGiver/document";

var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    siblings: {
      firstName: { required },
      lastName: { required },
      relation: { required },
      uci: { required },
      email: { required, email },
    },
    form: {
      firstName: { required },
      lastName: { required },
      uci: { required, maxLength: maxLength(7) },
      dob: { required },
      address: { required },
      zip: { required, maxLength: maxLength(7) },
      status: { required },
      payersId: { required },
      joinDate: { required },
    },
  },
  components: {
    AddGoogleMap,
    Information,
    hrcomponent,
    documentGiver,
    task,
  },
  watch: {
    editEmployee: {
      handler: function () {
        this.otherStaffId = this.editEmployee.id;
        this.action == "Preview";
      },
    },
  },
  props: {
    action: String,
  },
  data() {
    const now = new Date();
    return {
      datePickerOptions: {
        format: "MM/DD/YYYY",
        maxDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        ),
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      isSaving: false,
      modelcloseable: false,
      isUpdating: false,
      clientFamilyId: [],
      fields: [
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "uci",
          label: "UCI",
        },
        {
          key: "relation",
          label: "Relation",
        },
      ],
      siblings: {
        firstName: "",
        lastName: "",
        relation: "",
        uci: "",
        portalCredentialsSent: false,
        email: "",
      },
      isSubmitting: false,
      currentPage: 1,
      isSiblingSaving: false,

      perPage: 50,
      isEditAble: true,
      payers: null,
      max: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      showAction: false,
      showSearch: false,

      image: "",
      form: {
        firstName: "",
        lastName: "",
        email: "",
        uci: "",
        dob: "",
        address: "",
        otherAddress: "",
        state: "",
        city: "",
        zip: "",
        status: "",
        longitude: "",
        latitude: "",
        payersId: "",
        joinDate: null,
        clientsSiblings: [],
      },
      statusdrpdown: [
        { value: "", text: "Please select status" },
        { value: "New Referral", text: "New Referral" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Pending Termination", text: "Pending Termination" },
        { value: "Terminated", text: "Terminated" },
      ],
      url: null,
      otherStaffId: null,
      image: null,
    };
  },
  mounted() {
    if (this.editEmployee) {
      this.url = this.editEmployee.image;
      this.form = this.editEmployee;
      this.otherStaffId = this.editEmployee.id;
      this.isEditAble = false;
      // this.fetchData().catch((error) => {
      //   console.error(error);
      // });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-3" },
    ]);

    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
    wizard.on("beforeNext", function (/*wizardObj*/) {});

    // Change event
    wizard.on("change", function () {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {
    statusColor() {
      var color = "";
      switch (this.form.status) {
        case "Active":
          color = "green";
          break;
        case "Inactive":
          color = "#ffca00";
          break;
        case "New Referral":
          color = "#2255e6";
          break;
        case "Pending Termination":
          color = "orange";
          break;
        case "Terminated":
          color = "red";
      }
      return color;
    },
    statusBackGroundColor() {
      var color = "";
      switch (this.form.status) {
        case "Active":
          color = "#e8fff3";
          break;
        case "Inactive":
          color = "#fff8dd";
          break;
        case "New Referral":
          color = "#f8f5ff";
          break;
        case "Pending Termination":
          color = "#f7dfb5";
          break;
        case "Terminated":
          color = "#fff5f8";
      }
      return color;
    },
    ...mapGetters(["editEmployee"]),
  },
  destroyed() {
    this.$store.dispatch("emptyeditEmployee", null);
  },
  methods: {
    updateData() {
      this.editEmployee;
    },
    submit: function (e) {
      e.preventDefault();
    },
    dobformat(obj) {
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    onfocusin() {},
    foramtDateHeading(obj) {
      if (obj) {
        let d = new Date(obj);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${mo}-${da}-${ye}`;
      }
    },
    Close: function (e) {
      e.preventDefault();
      this.$emit("employeeSent");
    },
    Delete() {
      var id = this.otherStaffId;

      Swal.fire({
        icon: "error",
        text: "Are you sure you want to remove?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          fetchApi(
            `api/Employee/RemoveEmployeeProfileImage?id=${id}`,
            null,
            "Post",
            { "Content-Type": "application/json" }
          ).then((response) => {
            if (response.message == "Success") {
              this.url = null;
            }
          });
        }
      });
    },
    Preview_image() {
      var id = this.otherStaffId;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        this.url = e.target.result;
        fetchApi(
          "api/Employee/UploadEmployeeProfileImage",
          { id: id, image: this.url },
          "Post",
          { "Content-Type": "application/json" }
        );
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
      this.url = URL.createObjectURL(this.image);

      const reader2 = new FileReader();
      reader2.onloadend = () => {
        this.form.image = reader2.result;
      };
      reader2.readAsDataURL(file);
    },
  },
};
</script>
<style scoped>
.wizard.wizard-3.wizard-nav.wizard-steps.wizard-step[data-wizard-state="current"] {
  background-color: #f69463;
}
.wizard.wizard-3
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label {
  color: #f69463 !important;
}
.wizard.wizard-3
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label
  .wizard-bar:after {
  width: 100%;
  background-color: #f69463;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.red {
  color: red;
}
.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
