<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">OTHER STAFF</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="editRecord">
          <i class="material-icons">add</i> <span>New Other STAFF</span></a
        >
      </span>
    </div>

    <div>
      <v-row>
       <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search User"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >  <template #label>
                           <i class="fas fa-search"></i>  Search Staff 
                          </template> </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        >
      </v-row>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        select-mode="single"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        selectable
        striped
        hover
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item.id)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmOtherStaffModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(dob)="row">
          {{ dobformat(row.item) }}
        </template>
        <template #cell(lastName)="row">
          {{ nameValue(row.item.lastName) }}
        </template>
        <template #cell(firstName)="row">
          {{ nameValue(row.item.firstName) }}
        </template>
        <template #cell(address)="row">
          {{ row.item.address }}  {{ row.item.otherAddress }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
                  v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
             <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div> </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      size="xl"
      id="createStaff"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <template slot="modal-title"><span v-if="action != 'Preview'"> Add New Staff </span></template>
      <createwizard 
        @employeeSent="closeModal"
      ></createwizard>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmOtherStaffModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(careGiverId)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmOtherStaffModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import createwizard from "./addOtherStaff.vue";
import { mapGetters } from "vuex";
import datatable from "../../components/datatable.vue";
export default {
  components: {
    createwizard,
    datatable,
  },
  props: { 
  },
  data() {
    return { 
        action: "",
      careGiverId: "",
      isfetching: false,
      showChangePass: true,
      clientid: "",
      items: [],
      isfetching: false,
      isRemoving: false,
      fields: [
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "empID",
          label: "Employee ID",
        },
        {
          key: "dob",
          label: "DOB",
        },
        {
          key: "address",
          label: "Address",
        }, 

        {
          key: "employeeType",
          label: "Employee Type",
        },

        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapGetters(["OtherStaff", "totalOtherStaffRow"]),
  },
  methods: {
    nameValue(obj) {
      if (obj && obj.length > 10) {
        return obj.substring(0, 10).concat("...");
      } else {
        return obj;
      }
    },
     addressValue(obj) {
      if (obj && obj.length > 50) {
        return obj.substring(0, 50).concat("...");
      } else {
        return obj;
      }
    },
    dobformat(obj) {
      var date = new Date(obj.dob);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    async Search() {
      this.fetchData();
    },
    async fetchData() {
      this.search = this.search == null ? "" : this.search;
      this.isfetching = true;
      await this.$store
        .dispatch("getStaff", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = response.data.output;
            this.totalItems = response.data.totalRow;
          }
        })
        .catch((ex) => {
           this.isfetching = false;
             Swal.fire({
              title: "",
              text: "Error!",
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
          
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    onRowSelected(items) {
      this.Edit(items[0].id);
    },
    sendInfo(item) {
      this.careGiverId = item;
    },
    async remove(obj) {
      this.isRemoving = true;
      await this.$store
        .dispatch("removeStaff", {
          id: obj,
        })
        .then((response) => {
          this.isRemoving = false;
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Staff has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.fetchData();
        })
        .catch((ex) => {
          
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmOtherStaffModal");
    },
    closeModal() {
      this.$bvModal.hide("createStaff");
    },
    editRecord() {
      this.action="";
      this.$store.dispatch("editStaff", null);
      this.$bvModal.show("createStaff");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async Edit(obj) {
      this.isfetching = true; 
      if (obj) {
        await this.$store.dispatch("editStaff", obj).then(() => { 
          this.isfetching = false;
            this.action="Preview",
          this.$bvModal.show("createStaff");
        });
      }
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalClientRow: {
      handler: function () {
        this.items = this.clients;
      },
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
</style>
