<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.formPhone.employeeID || this.form.id"
        class="page-title"
        style="padding-bottom: 80px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submitOtherStaff">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSaving">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>

      <div class="page-title pageheading" style="height: 80px">
        <b-button
          v-if="!this.formPhone.employeeID"
          size="lg"
          variant="success"
          class="float-right green-btn"
          @click="submitOtherStaff()"
        >
          Save Other Staff Info
          <span v-if="isSaving">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
      </div>
      <br />

      <div class="row">
        <!-- Employee Type -->
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.employeeType.$error }"
        >
          <label> Employee Type </label><span class="red"> *</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.form.employeeType.$model"
            :disabled="isEditAble"
            :rules="['Required']"
            :options="employeeType"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.employeeType.$error">
            <span v-if="$v.form.employeeType.$error"
              >Employee Type is required</span
            >
          </div>
        </div>
        <!-- Status -->
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.status.$error }"
        >
          <label> Status </label><span class="red"> *</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.form.status.$model"
            :disabled="isEditAble"
            :rules="[(v) => !!v || 'Item is required']"
            :options="status"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.status.$error">
            <span v-if="$v.form.status.$error">Status is required</span>
          </div>
        </div>
        <!-- firstname -->
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.firstName.$error }"
        >
          <v-text-field
            v-model.trim="$v.form.firstName.$model"
            :disabled="isEditAble"
            required
            maxlength="50"
          >
            <template #label>
              First Name
              <span class="red">*</span>
            </template>
          </v-text-field>

          <div class="invalid-feedback" v-if="$v.form.firstName.$error">
            <span v-if="$v.form.firstName.$error">First Name is required</span>
          </div>
        </div>
        <!-- last name -->
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.lastName.$error }"
        >
          <v-text-field
            :disabled="isEditAble"
            v-model.trim="$v.form.lastName.$model"
            maxlength="50"
          >
            <template #label>
              Last Name
              <span class="red">*</span>
            </template>
          </v-text-field>

          <div class="invalid-feedback" v-if="$v.form.lastName.$error">
            <span v-if="$v.form.lastName.$error">Last Name is required</span>
          </div>
        </div>
        <!-- Employee Id -->
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.empID.$error }"
        >
          <v-text-field
            v-model.trim="$v.form.empID.$model"
            :disabled="isEditAble"
            type="number"
            maxlength="12"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            label="otherStaffID"
          >
            <template #label>
              Employee Id
              <span class="red">*</span>
            </template></v-text-field
          >

          <div class="invalid-feedback" v-if="$v.form.empID.$error">
            <span v-if="$v.form.empID.$error">Employee is required</span>
          </div>
        </div>
        <!-- dob -->
        <div class="col-md-6 mb-form" :class="{ invalid: $v.form.dob.$error }">
          <label>DOB</label> <span class="red">*</span>
          <date-picker
            ref="dp1"
            :disabled="isEditAble"
            @focusin.native="onfocusin"
            placeholder="Choose Birth Date"
            :config="datePickerOptions"
            v-model.trim="$v.form.dob.$model"
          ></date-picker>

          <div class="invalid-feedback" v-if="$v.form.dob.$error">
            <span v-if="$v.form.dob.$error">Dob is required</span>
          </div>
        </div>
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.form.gender.$error }"
        >
          <label> Gender </label><span class="red">*</span>
          <b-form-select
            class="form-control"
            :disabled="isEditAble"
            v-model.trim="$v.form.gender.$model"
            :rules="[(v) => !!v || 'Item is required']"
            :options="gender"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.gender.$error">
            <span v-if="$v.form.gender.$error">Gender is required</span>
          </div>
        </div>
        <!-- ssn -->
        <div class="col-md-6 mb-form" :class="{ invalid: $v.form.ssn.$error }">
          <v-text-field
            v-model.trim="$v.form.ssn.$model"
            label="SSN"
            type="number"
            :disabled="isEditAble"
            :max="9"
            maxlength="9"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          >
            <template #label>
              SSN
              <span class="red">*</span>
            </template></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.ssn.$error">
            <span v-if="!$v.form.ssn.maxLength">SSN should be less than 9</span>
            <span v-else-if="$v.form.ssn.$error">Ssn is required</span>
          </div>
        </div>
        <div class="col-md-6 mb-form">
          <span
            >Enter the email where Caregiver portal credentials will be
            sent.</span
          >
          <!-- firstname -->
          <div class="row">
            <div
              class="col-md-12 mb-form"
              :class="{ invalid: $v.form.email.$error }"
            >
              <v-text-field
                v-model.trim="$v.form.email.$model"
                required
                :disabled="isEditAble"
                maxlength="50"
              >
                <template #label>
                  Email
                  <span class="red">*</span>
                </template>
              </v-text-field>

              <div class="invalid-feedback" v-if="$v.form.email.$error">
                <span v-if="$v.form.email.$error">Email is In_valid</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <b-button disabled size="lg" variant="success" class="mt-5">
            Resend Portal Credentials
          </b-button>
        </div>
        <div
          v-if="this.formPhone.employeeID"
          class="page-title pageheading"
          style="height: 50px; width: 100%"
        >
          <span class="float-right green-btn">
            <a class="btn btn-success mr-3" @click="create">
              <i class="material-icons">add</i> <span>New Phone</span>
            </a>
          </span>
        </div>
        <div class="container mt-10" style="background-color: #ffffff">
          <b-table
            class="table table-bordered"
            show-empty
            :items="items"
            :fields="fields"
            striped
            hover
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(actions)="row">
              <a @click="Edit(row.item)" class="mr-1">
                <i class="far fa-eye edit_btn"></i>
              </a>
              <a
                v-b-modal.confirmOtherStaffPhoneModal
                @click="sendInfo(row.item.id)"
                class="mr-1"
              >
                <i class="fas fa-trash-alt del_btn"></i>
              </a>
            </template>
            <template v-slot:head(phoneNumber)>
              <span> Phone Number <small>(xxx) xxx-xxxx</small> </span>
            </template>
            <template #cell(isPrimary)="row">
              {{ isPrimaryValue(row.item.isPrimary) }}
            </template>
            <template #cell(notes)="row">
              {{ notesValue(row.item.notes) }}
            </template>
          </b-table>
        </div>
        <v-divider></v-divider>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h3>Location <span class="red">*</span></h3>
          <div :class="{ invalid: $v.form.address.$error }">
            <span v-if="isEditAble">
              <v-text-field
                v-model.trim="$v.form.address.$model"
                label="Address Line 1*"
                disabled
                maxlength="100"
                ><template #label>
                  Address
                  <span class="red">*</span>
                </template></v-text-field
              ></span
            >
            <span v-else>
              <gmap-autocomplete
                style="width: 100%; border-style: solid; height: 40px"
                :placeholder="form.address"
                :value="form.address"
                @place_changed="setPlace"
              >
              </gmap-autocomplete>

              <div class="invalid-feedback" v-if="$v.form.address.$error">
                <span v-if="$v.form.address.$error">Address is required</span>
              </div>
              <!-- <span class="ml-5">
                <a class="btn btn-success mr-3" @click="usePlace">
                  <i class="material-icons">add</i> <span>Add</span>
                </a></span
              > -->
            </span>
          </div>
        </div>
        <!-- <div class="col-md-3">
          <span>Unit/Apt Number</span>
          <br />
          <input
            type="text"
            name=""
            :disabled="isEditAble"
            v-model="form.otherAddress"
            id=""
            placeholder=" Unit/Apt Number"
            style="
              margin-top: 10px;
              width: 20%;
              border-style: solid;
              height: 40px;
            "
          />
        </div> -->
      </div>
      <AddGoogleMap :addressMap="this.addressMap" @setAddress="address" />
      <v-divider></v-divider>
    </v-form>
    <b-modal
      size="xl"
      id="addPhone"
      title="Add Phone "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <v-row>
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.formPhone.deviceType.$error }"
        >
          <label> Phone Type </label><span class="red">*</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.formPhone.deviceType.$model"
            :rules="['Required']"
            required
            :options="phoneType"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.formPhone.deviceType.$error">
            <span v--if="$v.formPhone.deviceType.$error"
              >Device Type is required</span
            >
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="formPhone.isPrimary"
            label="Is Primary"
          ></v-checkbox>
        </v-col>
        <div
          class="col-md-6 mb-form"
          :class="{ invalid: $v.formPhone.phoneNumber.$error }"
        >
          <v-text-field
            v-model.trim="$v.formPhone.phoneNumber.$model"
            label="Number"
            maxlength="14"
            type="tel"
            @input="enforcePhoneFormat()"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            ><template #label>
              Phone Number
              <span class="red">*</span>
            </template></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.formPhone.phoneNumber.$error">
            <span v-if="$v.formPhone.phoneNumber.$error"
              >Phone Number is required</span
            >
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-textarea
            maxlength="250"
            filled
            v-model="formPhone.notes"
            auto-grow
            label="Notes"
            rows="3"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
      </v-row>
      <span class="green-btn">
        <a class="btn btn-success mr-3" @click="ok()"
          ><span
            >Save
            <span v-if="isSaving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span></span
        ></a>
      </span>
      <span class="green-btn">
        <a class="btn btn-danger mr-3" @click="$bvModal.hide('addPhone')"
          ><span>Cancel</span></a
        >
      </span>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmOtherStaffPhoneModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(employeePhoneId)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmOtherStaffPhoneModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email,
  minLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import AddGoogleMap from "../AddGoogleMap";
var today = new Date();
export default {
  mixins: [validationMixin],
  components: { AddGoogleMap },
  validations: {
    form: {
      employeeType: { required },
      status: { required },
      firstName: { required },
      lastName: { required },
      empID: { required },
      dob: { required },
      gender: { required },
      address: { required },
      email: { required, email },
      ssn: { required, maxLength: maxLength(9) },
      // zip: { required, maxLength: maxLength(9) },
    },
    formPhone: {
      phoneNumber: { required, minLength: minLength(10) },
      deviceType: { required },
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    if (this.editEmployee) {
      this.form = this.editEmployee;
      this.formPhone.employeeID = this.editEmployee.id;
      this.form.dob = this.dobformat(this.form.dob);
      this.addressMap.longitude = this.form.longitude;

      this.addressMap.latitude = this.form.latitude;
      this.fetchData().catch((error) => {});
    } else {
      this.isEditAble = false;
    }
  },
  computed: { ...mapGetters(["editEmployee"]) },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      phone_number: 0,
      addressMap: {
        longitude: "",
        latitude: "",
      },
      isEditAble: true,
      currentPage: 1,
      perPage: 50,
      isSaving: false,
      Email: "",
      items: [],
      employeePhoneId: "",
      fields: [
        {
          key: "isPrimary",
          label: "Primary",
        },
        {
          key: "deviceType",
          label: "Phone Type",
        },
        {
          key: "phoneNumber",
          label: "Number",
        },
        {
          key: "notes",
          label: "Notes",
        },

        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPlace: "",
      isEditAble: true,
      isSubmitting: false,
      isRemoving: false,
      valid: true,
      form: {
        employeeType: "",
        status: "",
        firstName: "",
        gender: "",
        latitude: "443243243",
        longitude: "23213213",
        lastName: "",
        empID: "",
        dob: "",
        email: "",
        ssn: "",
        ssn: "",
        address: "",
        otherAddress: "",
        state: "",
        city: "",
        zip: "",
      },
      formPhone: {
        employeeID: "",
        phoneNumber: 0,
        isPrimary: false,
        notes: "",
        deviceType: "",
      },
      employeeType: [
        { value: "", text: "Please Select Employee Type" },
        { value: "Super Admin", text: "Super Admin" },
        { value: "Admin", text: "Admin" },
        { value: "Coordinator", text: "Coordinator" },
      ],
      status: [
        { value: "", text: "Please " },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Terminated", text: "Terminated" },
      ],
      gender: [
        { value: "", text: "Please Select Gender" },
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      phoneType: [
        { value: "", text: "Please Select Phone Type" },
        { value: "Cell", text: "Cell" },
        { value: "Home", text: "Home" },
        { value: "Work", text: "Work" },
      ],
      datePickerOptions: {
        format: "MM/DD/YYYY",
        maxDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        ),
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
    };
  },
  methods: {
    enforcePhoneFormat() {
      let x = this.formPhone.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.formPhone.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    usePlace() {
      this.addressMap = this.currentPlace;
      this.form.longitude = this.currentPlace.geometry.location
        .lat()
        .toString();
      this.form.latitude = this.currentPlace.geometry.location.lng().toString();
      this.form.address = this.currentPlace.formatted_address;
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addressMap = this.currentPlace;
      this.form.longitude = this.currentPlace.geometry.location
        .lat()
        .toString();
      this.form.latitude = this.currentPlace.geometry.location.lng().toString();
      this.form.address = this.currentPlace.formatted_address;
    },
    address(obj) {
      this.form.longitude = obj.lng.toString();
      this.form.latitude = obj.lat.toString();
    },
    dobformat(obj) {
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    onfocusin() {},
    async fetchData() {
      this.isfetching = true;
      await this.$store
        .dispatch("getOtherStaffPhone", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          id: this.formPhone.employeeID,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = response.data.output;
            this.totalItems = response.totalRow;
          }
        })
        .catch((ex) => {});
    },
    sendInfo(item) {
      this.employeePhoneId = item;
    },
    isPrimaryValue(obj) {
      return obj ? "Yes" : "No";
    },
    notesValue(obj) {
      if (obj && obj.length > 40) {
        return obj.substring(0, 40).concat("...");
      } else {
        return obj;
      }
    },
    submitOtherStaff() {
      this.$v.$touch();
      this.form.dob = new Date(this.form.dob);
      this.form.dob.setHours(this.form.dob.getHours() + 13);
      if (!this.$v.form.$invalid) {
        this.isSaving = true;
        if (this.form.id) {
          this.$store
            .dispatch("updateOtherStaff", this.form)
            .then(async (response) => {
              if (response.message == "Success") {
                this.isEditAble = true;
                this.formPhone.employeeID = response.data;
                await this.$store.dispatch("editOtherStaff", response.data);
                this.$emit("updateData");
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: "The OtherStaff has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
            })
            .catch((ex) => {
              this.isSaving = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.$store
            .dispatch("saveOtherStaff", this.form)
            .then(async (response) => {
              if (response.message == "Success") {
                this.isEditAble = true;
                this.formPhone.employeeID = response.data;
                await this.$store.dispatch("editOtherStaff", response.data);
                this.$emit("updateData");
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: "The OtherStaff has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
            })
            .catch((ex) => {
              this.isSaving = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
      }
    },
    submit: function (e) {
      e.preventDefault();
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.formPhone.$invalid) {
        this.isSaving = true;
        if (this.formPhone.id) {
          this.$store
            .dispatch("putOtherStaffPhone", this.formPhone)
            .then((response) => {
              if (response.message == "Success") {
                this.fetchData();
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: "The  OtherStaff  Phone has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.$bvModal.hide("addPhone");
              } else {
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
                this.$bvModal.hide("addPhone");
              }
            })
            .catch((ex) => {
              this.isSaving = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.$store
            .dispatch("saveOtherStaffPhone", [this.formPhone])
            .then((response) => {
              if (response.message == "Success") {
                this.fetchData();
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: "Phone number has been added successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.$bvModal.hide("addPhone");
              } else {
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
                this.$bvModal.hide("addPhone");
              }
            })
            .catch((ex) => {
              this.isSaving = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
      }
    },
    async remove(obj) {
      this.isRemoving = true;
      await this.$store
        .dispatch("RemoveOtherStaffPhone", {
          id: obj,
        })
        .then((response) => {
          this.isRemoving = false;
          this.$bvModal.hide("confirmOtherStaffPhoneModal");
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Phone has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.fetchData();
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmTaskModal");
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    async Edit(obj) {
      if (obj != undefined) {
        this.formPhone = obj;
        this.enforcePhoneFormat();
        this.$bvModal.show("addPhone");
      }
    },
    resetForm() {},
    create() {
      (this.formPhone.phoneNumber = ""),
        (this.formPhone.isPrimary = false),
        (this.formPhone.notes = ""),
        (this.formPhone.deviceType = ""),
        this.$bvModal.show("addPhone");
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
